@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-inputs/styles/material.css';

body {
  /* Add some space to the bottom of the page so clickable things don't conflict with mobile Safari's bottom toolbar. */
  padding-bottom: 50px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.e-grid td.e-selectionbackground {
  background: #19857b3b !important;
}

.e-grid .e-altrow {
  background-color: #fafafa;
}

.parts-container .e-detailrowcollapse,
.parts-container .e-detailrowexpand,
.parts-container .e-detailindentcell {
  display: none !important;
}

.parts-container .e-detailcell {
  padding-left: 15px;
  display: block;
}

.parts-container .e-detailcell .e-toolbar-items {
  display: flex;
}

.parts-container .e-bigger .e-grid.e-row-responsive .e-gridcontent .e-row {
  border-bottom: none;
}

#root .parts-container tr.e-row {
  border-bottom: none;
  padding-top: 15px;
}

#root .parts-container tr.e-detailrow {
  border-bottom: 3px solid #eee;
  padding-bottom: 15px;
}

.parts-container .e-grid .e-altrow {
  background-color: #fff;
}

#root .parts-container .e-grid .e-control.e-grid.e-lib {
  border-right: none;
}

#root .repairline-comments td.e-detailindentcell {
  height: 0;
}

#root .repairline-comments th.e-detailcell {
  padding-left: 15px;
  display: block;
}

#root .repairline-comments th.e-detailcell .e-toolbar-items {
  display: flex;
}

#root .repairline-comments .e-detailrowcollapse, #root .repairline-comments .e-detailrowexpand {
  padding: 10px 0;
}

.remove-column-header-padding {
  padding: 0 !important;
}

.MuiTooltip-tooltip {
  text-align: center;
}

/* Hide empty cells in vertical grids entirely */
td.e-rowcell[role=gridcell][aria-label^=' is template cell column header'], td.e-rowcell[role=gridcell][aria-label^=' column header'] {
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Hide the dialog "Details of {primary key value}" header text when editing a comment. */
.e-edit-dialog .e-dlg-header-content .e-dlg-header .e-res-custom-element .e-dlg-custom-header {
  visibility: hidden;
}

#notistack-snackbar {
  white-space: pre-line;
}

.e-dropdownbase .e-list-item, .e-dropdownbase .e-list-item {
  white-space: normal;
  text-indent: 0;
  line-height: 18px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  padding: 6px 10px 3px 10px;
}

.e-dropdownbase .e-list-item:nth-child(odd) {
  background-color: #eee;
}

.e-dropdownbase .e-list-item.e-hover {
  background-color: #ccc;
}

.e-popup.e-popup-open.e-dialog {
  top: 60px !important;
}

.e-popup-full-page .e-popup-full-page.e-ddl.e-popup.e-ddl-device-filter {
  inset: 0 !important;
  position: fixed !important;
}
